<template>
	<h2>
		{{ unsluggify(semester) }}
	</h2>
	<p>{{ unsluggify(subject) }}</p>
	<div class="students">
		<button class="btn striped-shadow dark" @click="shuffleStudents">
			<span>Randomize</span>
		</button>
		<div v-if="finalStudentList" :style="gridTemplate" id="student-list">
			<div
				v-for="(student, index) in studentListBasedOnLayout"
				:key="index"
				v-bind:class="studentClass(student)"
			>
				{{ student }}
			</div>
		</div>
	</div>
</template>

<script>
import ClassService from '@/services/ClassService.js'

export default {
	props: ['id', 'subject', 'semester'],
	data() {
		return {
			usedPositions: [], // used to avoid duplicates when randomizing a position for a student with restrictions
			fixedPositions: null, // used to lock students in specific keys during shuffle
			allStudents: null,
			studentNames: null,
			finalStudentList: null,
			columns: null,
			rows: null,
			studentListBasedOnLayout: [],
			// On consecutive runs
			resetValues: {
				fixedPositions: null,
				studentNames: null,
			},
		}
	},
	computed: {
		studentClass() {
			return (student) => {
				return !student ? 'empty' : ''
			}
		},
		gridTemplate() {
			return `grid-template-columns: repeat(${this.columns}, 1fr);`
		},
		shuffle() {
			return (a) => {
				return a.reduce((l, e, i) => {
					const j = Math.floor(Math.random() * (a.length - i) + i) // j is in [i, a.length[
					;[a[i], a[j]] = [a[j], a[i]]
					return a
				}, a)
			}
		},
		fixedShuffleIndex() {
			return (a, f) => {
				let w = this.shuffle(
					a.reduce((acc, e, i) => {
						if (!f[i]) {
							acc.push(e)
						}
						return acc
					}, [])
				)
				return f.reduce((acc, e, i) => {
					if (e) {
						acc.splice(i, 0, a[i])
					}
					return acc
				}, w)
			}
		},
		unsluggify() {
			return (slug) => {
				const result = slug.replace(/-/g, ' ')
				const words = result.split(' ')

				for (let i = 0; i < words.length; i++) {
					words[i] = words[i][0].toUpperCase() + words[i].substr(1)
				}

				return words.join(' ')
			}
		},

		getRestrictedPosition() {
			return (row, col) => {
				// Determines which key to use given row and/or column number
				let possiblePositions = [];

				this.availableSeats.forEach((seat, key) => {
					// If there is a row restriction and it doesn't match, return early
					if (row && seat.row != row) return;
					
					// If there is a column restriction and it doesn't match, return early
					if (col && seat.col != col) return;
					
					// Ensure there are no repeats
					if (this.usedPositions.includes(key)) return;

					possiblePositions.push(key);
				});

				if (possiblePositions.length === 0) {
					throw new Error(`No available seats match the given restrictions: row ${row}, col ${col}`);
				}

				let position = possiblePositions[Math.floor(Math.random() * possiblePositions.length)];
				this.usedPositions.push(position);
				return position;
			}
		},
		arrayMove() {
			return (arr, fromIndex, toIndex) => {
				let originalValue = arr[toIndex]
				arr[toIndex] = arr[fromIndex]
				arr[fromIndex] = originalValue
				return arr
			}
		},
		reset() {
			return () => {
				this.fixedPositions = this.resetValues.fixedPositions
				this.studentNames = this.resetValues.studentNames
			}
		},
		saveStudentList() {
			return (studentListBasedOnLayout) => {
				let termID = this.id

				ClassService.saveStudentList(termID, studentListBasedOnLayout)
			}
		},
	},
	methods: {
		shuffleStudents() {
			this.studentListBasedOnLayout = []
			this.reset()
			this.allStudents.forEach((student) => {
				let row = student.restrictions.row;
				let col = student.restrictions.col;
				let hasRestriction = student.restrictions.on;
				let toIndex = 0;
				let fromIndex = 0;

				if (!hasRestriction) return;

				// Get the new index based on restrictions
				toIndex = this.getRestrictedPosition(row, col);
				fromIndex = this.studentNames.indexOf(student.full_name);

				// Now move the student to the new index
				this.studentNames = this.arrayMove(
					this.studentNames,
					fromIndex,
					toIndex
				);
				this.fixedPositions[toIndex] = true;
			});

			this.finalStudentList = this.fixedShuffleIndex(
				this.studentNames,
				this.fixedPositions
			)

			this.usedPositions = []
			this.generateFinalArray()
		},
		generateFinalArray() {
			let availableSeatsIndex = this.availableSeats.reduce((seats, el) => {
				seats.push(el.index)
				return seats
			}, [])
			// console.log(this.finalStudentList)
			for (let i = 1; i <= this.columns * this.rows; i++) {
				if (availableSeatsIndex.indexOf(i) != -1) {
					this.studentListBasedOnLayout[i - 1] =
						this.finalStudentList.shift() || ''
				} else {
					this.studentListBasedOnLayout[i - 1] = ''
				}
			}

			if (this.studentListBasedOnLayout.length) {
				this.saveStudentList(this.studentListBasedOnLayout)
			}
			// console.log(this.studentListBasedOnLayout)
		},
	},
	created() {
		ClassService.getClasses()
			.then((response) => {
				let classes = response.data
				let semester = this.unsluggify(this.semester)

				let subjects = classes[semester].subjects
				subjects.forEach((data) => {
					if (
						data.name.toLowerCase() ==
						this.unsluggify(this.subject).toLowerCase()
					) {
						this.availableSeats = data.seating.available_seats
						this.allStudents = data.students

						this.studentNames = data.students.reduce((students, el) => {
							students.push(el.full_name)
							return students
						}, [])

						this.fixedPositions = data.students.map(() => false)

						this.columns = data.seating.cols
						this.rows = data.seating.rows

						// Saving initial values
						this.resetValues.fixedPositions = this.fixedPositions
						this.resetValues.studentNames = this.studentNames

						// Load initial student chart (if exists)
						if (data.saved_student_seating) {
							this.studentListBasedOnLayout = data.saved_student_seating
							this.finalStudentList = true // normally should be an array | faking this here
						}
					}
				})
			})
			.catch((error) => {
				console.log(error)
			})
	},
}
</script>

<style scoped lang="scss">
#student-list {
	margin: 20px 10px 0;
	display: grid;
	grid-column-gap: 10px;
	grid-row-gap: 50px;

	div {
		padding: 50px 20px;
		font-size: 55px;
		border: 2px solid black;

		&.empty {
			border: none;
			width: 20px;
			visibility: hidden;
		}
	}
}

button {
	overflow: visible;
	border: 0;
	padding: 0;
	margin: 10px 0 25px;
}
.btn.striped-shadow span {
	display: block;
	position: relative;
	z-index: 2;
	border: 5px solid;
}
.btn.striped-shadow.white span {
	border-color: #fff;
	color: #fff;
	background: #77bfa1;
}

.btn.striped-shadow.blue span {
	border-color: #4183d7;
	background: #77bfa1;
	color: #4183d7;
}
.btn.striped-shadow.dark span {
	border-color: #393939;
	background: #77bfa1;
	color: #393939;
}

.btn {
	font-family: 'Bungee Shade', sans-serif;
	height: 80px;
	line-height: 65px;
	display: inline-block;
	letter-spacing: 1px;
	position: relative;
	font-size: 1.35rem;
	transition: opacity 0.3s, z-index 0.3s step-end, -webkit-transform 0.3s;
	transition: opacity 0.3s, z-index 0.3s step-end, transform 0.3s;
	transition: opacity 0.3s, z-index 0.3s step-end, transform 0.3s,
		-webkit-transform 0.3s;
	z-index: 1;
	background-color: transparent;
	cursor: pointer;
}

.btn {
	width: 155px;
	height: 48px;
	line-height: 38px;
}

button.btn.striped-shadow.dark:after,
button.btn.striped-shadow.dark:before {
	background-image: linear-gradient(
		135deg,
		transparent 0,
		transparent 5px,
		#393939 5px,
		#393939 10px,
		transparent 10px
	);
}

button.btn.striped-shadow:hover:before {
	max-height: calc(100% - 10px);
}

button.btn.striped-shadow:after {
	width: calc(100% - 4px);
	height: 8px;
	left: -10px;
	bottom: -9px;
	background-size: 15px 8px;
	background-repeat: repeat-x;
}
button.btn.striped-shadow:after,
button.btn.striped-shadow:before {
	content: '';
	display: block;
	position: absolute;
	z-index: 1;
	transition: max-height 0.3s, width 0.3s, -webkit-transform 0.3s;
	transition: transform 0.3s, max-height 0.3s, width 0.3s;
	transition: transform 0.3s, max-height 0.3s, width 0.3s,
		-webkit-transform 0.3s;
}

.btn.striped-shadow:hover {
	-webkit-transform: translate(-12px, 12px);
	-ms-transform: translate(-12px, 12px);
	transform: translate(-12px, 12px);
	z-index: 3;
}

button.btn.striped-shadow:hover:after,
button.btn.striped-shadow:hover:before {
	-webkit-transform: translate(12px, -12px);
	-ms-transform: translate(12px, -12px);
	transform: translate(12px, -12px);
}
button.btn.striped-shadow:before {
	width: 8px;
	max-height: calc(100% - 5px);
	height: 100%;
	left: -12px;
	bottom: -5px;
	background-size: 8px 15px;
	background-repeat: repeat-y;
	background-position: 0 100%;
}
</style>
